@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;




[data-sparta-container] .seo-overview-engagement-module {

  #seo-engagement-bg {
    bottom: 270px;
    position: absolute;

    @media #{$medium-only} {
      bottom: 75px;
    }
    @media #{$large-up} {
      bottom: 126px;
    }
  }
  $image-height-small: 180px;
  $image-height-medium: 180px;
  $image-height-large: 320px;
  .picture-box {
    height: $image-height-small;
    @media #{$medium-only} {
      height: $image-height-medium;
    }
    @media #{$large-up} {
      height: $image-height-large;
    }
    picture {
      height: $image-height-small;
      &>img {
        height: $image-height-small;
        @media #{$medium-only} {
          height: $image-height-medium;
        }
        @media #{$large-up} {
          height: $image-height-large;
        }
      }
    }
  }
  h2 {
    color: $color-regal-one;
    font-family: $cnx-light-family;
    font-size: 32px;
    line-height: 36px;
    margin: 25px $spacing-20 15px;
    padding: 0 $spacing-10 0 $spacing-10;
    text-align: center;

    @media #{$medium-only} {
      font-size: 32px;
      line-height: .8;
      margin: 60px 0 15px 0;
    }
    @media #{$large-up} {
      font-size: 44px;
      line-height: .8;
      margin: 60px 0 15px 0;
    }
  }
  .sub-heading {
    text-align: center;
  }
  .top-text {
    font-family: $cnx-family;
    line-height: 1.3;
    padding: 0 $spacing-10 $spacing-10 $spacing-10;
    text-align: center;

    @media #{$medium-only} {
      margin-bottom: 50px;
      margin-top: 15px;
    }
    @media #{$large-up} {
      margin-top: 45px;
      margin-bottom: 65px;
    }
    .title {
      color: $color-black-alt;
      font-family: $cnx-medium-family;
      font-size: 16px;
      line-height: 1.4;
      margin: 3px 0 $spacing-2;
    }
    p {
      color: $color-gray;
      font-size: 14px;
    }
  }
  .flex {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media #{$medium-up} {
      flex-direction: row;
      justify-content: center;
    }

    .equal-width{
      flex: 1 1 0;
    }
  }
  .temp-image {
    margin: 0 $spacing-40 $spacing-10;
    z-index: 1;

    @media #{$large-up} {
      margin-bottom: -8px;
    }

    img {
      height: 360px;
      margin-bottom: 22px;
      width: 180px;

      @media #{$medium-only} {
        margin-bottom: 0;
      }
      @media #{$large-up} {
        height: 600px;
        width: 300px;
      }
    }
  }
}
